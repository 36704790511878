<div class="text-center pd-thank-you">
  <br /><br />
  <h2 *ngIf="response == 'successfully'" class="color-orange | titulo-thnaks">
    TRANSACCIÓN FINALIZADA <br />
    Exitosamente
  </h2>
  <h2 *ngIf="response != 'successfully'" class="color-orange | titulo-thnaks">
    TRANSACCIÓN NO FINALIZO <br />
    CORRECTAMENTE
  </h2>
  <br />
  <img
    src="../../assets/images/transaction.png"
    style="padding-bottom: 40px; width: 31%"
  />
  <br />
  <p style="color: #000; font-size: 20px">
    La transacción ha finalizado
  </p>
  <br />
  <h4 *ngIf="false" class="color-orange | text-thanyou__yapuedes">
    YA PUEDES UTILIZAR KAMGUS
  </h4>
  
  
   <!--APP CARDS TELEFONO-->
  <br />
  <!--Botones-->

<!--BOTONES-->
<!--Botones responsive-->
<div class="container-fluid ocultar">
  <div
    class="row"
  >
    
      <button *ngIf="false"
        class="col-md-6 mb-3 btn btnoranje"
        routerLink="/login"
      >
        Inicia Sesion Aquí
      </button>
    
    
      <button
        class="col-md-6  mb-3 btn btnblue"
      >
      <a
        href="https://apps.apple.com/us/app/id1462338345"
        style="color: #fff"
      >
        Volver a página web
      </a>
      </button>
    
  
</div>
</div>
<!--Botones responsive-->
<!--Responsive

  <div class="container contenedorBtnMovil ">
   
      <div class="responsive-thank__btn">
        <button
          routerLink="/login"
        >
          Inicia Sesion Aquí
        </button>
      </div>
      <div class="responsive-thank__btn">
        <a
          [href]="web"
          style="color: #fff"
        >
          Volver a página web
        </a>
      </div>
    
  
</div>
-->








  <!--
  <div class="container-fluid contenedorBtn ">
    <div
      class="row | justify-content-center"
      style="padding: 0% 6% 0% 6%; margin: 0% 6% 0% 6%; display: block ruby"
    >
      <div class="responsive-thank__btn">
        <button
          class="col-sm-14 | btn btn-block btn-warning btn-rounded | btn-thanks__login"
          routerLink="/login"
        >
          Inicia Sesion Aquí
        </button>
      </div>
      <div class="responsive-thank__btn">
        <a
          [href]="web"
          class="col-sm-14 | btn btn-facebook--thnksyou btn-lg btn-block"
          style="color: #fff"
        >
          Volver a página web
        </a>
      </div>
    
  </div>
</div>
-->