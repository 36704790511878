import { ActivatedRoute } from '@angular/router';
import { environment } from './../../../../environments/environment';
var TransactionResponseComponent = /** @class */ (function () {
    function TransactionResponseComponent(actRoute) {
        this.actRoute = actRoute;
        this.role = "";
        this.gateway = "";
        this.response = "";
        this.response = actRoute.snapshot.paramMap.get('response');
    }
    TransactionResponseComponent.prototype.ngOnInit = function () {
        this.web = environment.web;
        this.google = environment.googleDriver;
        this.ios = environment.iosDriver;
    };
    return TransactionResponseComponent;
}());
export { TransactionResponseComponent };
