import { ActivatedRoute } from '@angular/router';
import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-response',
  templateUrl: './transaction-response.component.html',
  styleUrls: ['./transaction-response.component.css']
})
export class TransactionResponseComponent implements OnInit {

   web;
    google;
    ios;
    role: string = "";
    gateway: string = "";
    response: string = "";
    constructor(public actRoute: ActivatedRoute) { 
      this.response = actRoute.snapshot.paramMap.get('response');
    }
  
    ngOnInit() {
      this.web = environment.web;
      this.google = environment.googleDriver;
      this.ios = environment.iosDriver;
    }
  

}
