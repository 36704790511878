import { TransactionResponseComponent } from './payment-center/transaction-response/transaction-response.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PagesComponent } from './pages.component';

import { ProgressComponent } from './progress/progress.component';
import { Graficas1Component } from './graficas1/graficas1.component';
import { IncrementadorComponent } from '../components/incrementador/incrementador.component';
import { GraficosComponent } from '../components/graficos/graficos.component';

import { SharedModule } from '../shared/shared.module';
import { PAGES_ROUTES } from './pages.routes';

import { ChartsModule } from 'ng2-charts';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { BrowserModule } from '@angular/platform-browser';
import { BuyLicenceComponent } from './buy-licence/buy-licence.component';
import { ServiceModule } from '../services/service.module';
import { PrivacyComponent } from './privacy/privacy.component';
import { PrivacyDriverComponent } from './privacy-driver/privacy-driver.component';
@NgModule({
    declarations: [
        PagesComponent,
        ProgressComponent,
        Graficas1Component,
        IncrementadorComponent,
        GraficosComponent,
        AccountSettingsComponent,
        BuyLicenceComponent,
        PrivacyComponent,
        PrivacyDriverComponent,
        TransactionResponseComponent,
    ],
    exports: [
        PagesComponent,
        ProgressComponent,
        Graficas1Component,
    ],
    imports: [
        SharedModule,
        PAGES_ROUTES,
        FormsModule,
        ChartsModule,
        ReactiveFormsModule,
        BrowserModule,
        ServiceModule,
    ]
})

export class PagesModule { }